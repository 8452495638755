import React from 'react';

import styled from 'styled-components';

import Layout from '../components/layout';
import HowlandJr from '../img/howlandjr.png';

const Container = styled.div`
  padding: 2rem;
  text-align: center;
  width: 100%;

  @media (min-width: 768px) {
    text-align: left;
    padding: 4rem;
  }
`;

const StyledPortrait = styled.img`
  max-width: 350px;
  padding: 30px;

  @media (min-width: 768px) {
    float: left;
  }
`;
export default function History() {
  return (
    <Layout>
      <Container>
        <StyledPortrait
          src={HowlandJr}
          alt="Portrait of Abraham H. Howland Jr."
        />
        <div>
          <h3>Abraham H. Howland Jr.</h3>
          <strong>
            <h6>Wor. Master, Eureka Lodge, 1869-70-71</h6>
            <h6>Dist. Dep. Grand Master, 1872-73-74</h6>
            <h6>Grand Master, 1884-85-86</h6>
          </strong>
        </div>
        <div>
          <p>
            <strong>
              A tribute to M.W. Abraham Hathaway Howland Jr. Prepared by: R.W.
              James Taylor Watson P.J.G.W.
            </strong>
          </p>
          <p>
            Abraham H. Howland Jr. came from hardy stock, the first Howland
            having come over on the Mayflower. Abraham&apos;s father was in
            whaling, a ship owner, one of the first to refine petroleum, a
            Massachusetts legislator -- three years, the first Mayor of New
            Bedford -- four terms, and a Mason.
          </p>
          <p>
            Abraham H. Howland Jr. was born in the city of New Bedford on May
            29, 1840. He served that city with distinction as Mayor 1875-1876.
            He was a Director of the utility company, several banks, and
            participated in civic groups.
          </p>
          <p>
            His Masonic career was exceptonally honorable, for he was held in
            high esteem and affection by his associates. He received the
            symbolic degrees in Eureka Lodge, New Bedford, starting in line as
            Senior Deacon, and served as Master 1869-1871. He also served as
            High Priest of Adoniram Royal Arch Chapter and as Commander of
            Sutton Comandery #16. In additiona, he was an Honorary Member of the
            Supreme Council of Sovereign Grand Inspectors of the Thirty-Third
            and last degree of the Acient Jurstiction of the United States of
            America, having previously received the degrees in the various
            bodies in the state under its juristiction. He served in the Grand
            Lodge of Masons in Massachusetts as District Deputy Grand Master in
            1872-1874, as Senior Grand Warden in 1876, as Deputy Grand Master in
            1878-1880, and as Grand Master 1884-1886. (It is interesting to note
            that he had competition for that office; total votes cast were 671,
            with Abraham H. Howland Jr. receiving 483 and his opponent 188.) At
            43 years of age he was one of the youngest ever elected to that
            office in this jurstiction.
          </p>
          <p>
            It is necessary to look at the history of our Grand Lodge to better
            understand the conditions when Most Worshipful Howland was
            installed. During the anti Masonic period 1826-1840, the number of
            lodges in Massachusetts declined from 107 to 52. The financial
            losses to Grand Lodge made it necessary to sell the Grand Lodge
            building in 1857. In 1859, the &quot;Winthrup House &quot; was
            purchased for a Grand Lodge. This building was totally conusmed by
            fire in 1864, resulting in a great financial loss. The ruins were
            removed and a new building constructed, using all the remaining
            funds of Grand Lodge and resulting ina huge mortgage. After this
            storm came the rainbow. The positive public relations during and
            after the Civil War of 1861-1865 resulted in many lodges being
            chartered and revenues increased. Never the less it look twenty
            years to pay off the debt. M.W. Howland inherited a debt free
            building in 1884, but with limited operating money. One of the prime
            objectives of his administration was to replace the Charity Fund
            established in 1811 that had grown to $50,000.00, but had been used
            to rebuild the Grand Lodge. It was his desire to set up this fund in
            such a way that it could only be used for charitable purposes. He
            accomplished this with an Act of Incorporation obtained from the
            Massachusetts General Court in 1884. The original limit of the fund
            was $1,000,000.00. This fund is known as the Masonic Education and
            Charity Fund, and under the control of eight Trustees the fund
            remains successful.
          </p>
          <p>
            M.W. Howland also urged the adoption of the district system of
            exemplification of the work and lectures of our ritual, which aso
            continues today. He indicated displeasure with the printed
            abbreviation of the ritual and advocated instruction from mouth to
            ear.
          </p>
          <p>
            In December at the close of his first year, he stated in his Annual
            Address, &quote;I have devoted such time to social, unofficial
            intercourse with the brethren in their lodges, as opportunity has
            afforded me and my physical condition has permitted.&quote; He was
            unanimously elected for another year.
          </p>
          <p>
            On February 21, 1885 he participated in the dedication of the new
            Masonic Hall, with 300 brethren present. They were primarily from
            Mt. Hope, King Philip, and Narrangansett lodges, and that evening
            1100 sat down for dinner.
          </p>
          <p>
            Throughout his stewardship in his high office, M.W. Howland often
            spoke of his activity being controlled by his physical condition. At
            some of the quarterly meeting, his address was omitted , but at
            other times he gave long informative and interesting addresses. He
            issued to his District Deputy Grand Masters in 1885, 28 warrants to
            convey his actions to lodges, such as healing of members, dedication
            of lodges, aying of corner stones etc., and required full reports on
            these activities.
          </p>
          <p>
            At his second Annual Address, speaking of the Grand Architect of the
            Universe he said, &quote;We thank him for the strength given us, by
            which we are enabled to discharge the duties incumbent upon
            us&quote;, and later in the address he said, &quote;We know not how
            soon we shall reach the limit of our earthly opportunity and be
            summoned to that lodge whither many of our brethren have gone before
            us.&quote; At this Annual Meeting he was again unanimously elected
            to his third year in office
          </p>
          <p>
            During his third year many repairs were made to the exterior, and
            many rooms redone in the interior of the Grand Lodge, made possible
            because of the prosperity of Masonry in Massachusetts. In his last
            Annual Address he said, &quote;In this my last formal communication
            to you, brethren of the Grand Lodge, I conclude with some personal
            allusions, which abide very strongly in my mind, and demand
            utterance. When I accepted the office of Grand Master three years
            ago, it was with sincere misgivings and much fear. Ability, fitness
            and strength of body, all seemed to be wanting for the wisest and
            most successful administration of the affairs of this Grand Body.
            Three years have speedily passed, and my abilities as well as my
            physical strength have been taxed to their utmost capacity. My
            misgiving was not a false estimate; for how could I have borne the
            duties of this high office with even comparative success, had it not
            been for the kind Providence that has blessed me, and for the
            loyalty, devotion, wisdom, and activity of the brethren who have
            surrounded me.&quote;
          </p>
          <p>
            After election and installation of officer, the newly installed
            Grand Master, M.W. Henry Endicott presented his predecessor with a
            Past Grand Master&apos;s jewel on behalf of Eureka Lodge. Beyond a
            doubt the three years as Grand Master took a greater toll than M.W.
            Abraham H. Howland Jr. realized, for he passed away on April 20,
            1887, cause of death, meningitis.
          </p>
          <p>
            Visiting the Rural Cemetary in the southwest section of New Bedford,
            one will find the Howland plot, defined by a low wall with
            sculptured stone. In the very center is a 15 foot high marble
            obelisk, the symbol of resurrection, with individual stones for each
            family member. On the marble stone of Abraham H. Howland Jr., the
            traditional symbol of square and compasses is not present, but the
            sprig of Acacia, the symbol of immortality, is created in bold
            relief on the top of his marker.
          </p>
          <p>
            Abraham H. Howland Jr. Lodge voted on May 8, 1923 to make suitable
            arrangements for placing a floral piece on the grave of M.W. Abraham
            H. Howland Jr., on Memorial Day of each year. A note from Mary
            Tucker Howland was received later expressing her thanks to the Lodge
            for the floral tribute in memory of her brother. A standing order
            with a local florist for this annual memorial was in effect for over
            forty years and has since been renewed.
          </p>
        </div>
      </Container>
    </Layout>
  );
}
